import React from 'react';

export const ImageDimensionsContext = React.createContext();

export const ImageDimensionsProvider = ({ element, props }) => {
  const {
    pageContext: { imageDimensions }
  } = props;
  return <ImageDimensionsContext.Provider value={imageDimensions}>{element}</ImageDimensionsContext.Provider>;
};

export default ImageDimensionsContext;
