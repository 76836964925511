import loadScript from '@upgrade/ui-utils/utils/load-script';
import smoothscroll from 'smoothscroll-polyfill';
import env from '@upgrade/ui-utils/env';
import { ensureUuidCookies } from '@upgrade/ras';
import { createRoot } from 'react-dom/client';
import { wrapStyles } from './src/root-wrapper';
import { ImageDimensionsProvider } from './src/components/ImageDimensionsProvider';

export const onClientEntry = () => {
  ensureUuidCookies();
};

export const onInitialClientRender = async () => {
  // non-blocking scripts
  loadScript(env.GATSBY_OPTIMIZELY_WEB_SCRIPT || process.env.GATSBY_OPTIMIZELY_WEB_SCRIPT).catch(error =>
    console.error('onInitialClientRender Optimizely', error?.message, error)
  );
  smoothscroll.polyfill();
  // blocking scripts
  // tealium configuration https://community.tealiumiq.com/t5/iQ-Tag-Management/Code-Center/ta-p/13631
  global.utag_data = global.utag_data || {};
  global.utag_cfg_ovrd = global.utag_cfg_ovrd || { noview: true };

  try {
    await loadScript(env.GATSBY_TEALIUM_UTAG_URL || process.env.GATSBY_TEALIUM_UTAG_URL);
    global.utag.view();
  } catch (error) {
    console.error('onInitialClientRender TEALIUM', error?.message, error);
  }
};

export const onRouteUpdate = () => {
  if (typeof window !== 'undefined') {
    window?.OneTrust?.initializeCookiePolicyHtml?.();
  }
  return global.utag && global.utag.view && global.utag.view();
};

export const wrapRootElement = wrapStyles;

export const wrapPageElement = ImageDimensionsProvider;

export const replaceHydrateFunction = () => {
  return (element, container = null) => {
    const root = createRoot(container);
    root.render(element);
  };
};
